import {ComponentRef, Directive, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from "@angular/core";

import {LoggerService} from "@app/_core/services/logger-service.service";
import {filter, Subject, takeUntil, tap} from "rxjs";
import {DestroyComponentData, DynamicComponent, DynamicComponentsContainerData} from "./dynamic-component.model";
import {DynamicComponentsService} from "./dynamic-components.service";

@Directive({
  selector: "[appDynamicComponents]",
  standalone: true
})
export class DynamicComponentsDirective implements OnInit, OnDestroy {
  @Input() appDynamicComponents: string = "";

  private isDestroyed$: Subject<void> = new Subject();
  private componentRefs: Map<string, ComponentRef<DynamicComponent>> = new Map<string, ComponentRef<DynamicComponent>>();

  constructor(private logger: LoggerService,
              private viewContainerRef: ViewContainerRef,
              private dynamicComponentsService: DynamicComponentsService,
              private renderer: Renderer2) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  ngOnInit(): void {
    this.dynamicComponentsService.componentsContainer$
      .pipe(
        filter((containerData: DynamicComponentsContainerData) => containerData !== null && containerData.containerName === this.appDynamicComponents),
        tap((containerData: DynamicComponentsContainerData) => this.createElements(containerData)),
        takeUntil(this.isDestroyed$))
      .subscribe();

    this.dynamicComponentsService.destroyComponents$
      .pipe(
        filter((containerData: DestroyComponentData) => containerData !== null && containerData.containerName === this.appDynamicComponents),
        tap((containerData: DestroyComponentData) => this.destroyComponent(containerData.id)),
        takeUntil(this.isDestroyed$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  public createElements(containerData: DynamicComponentsContainerData): void {
    for (const component of containerData.components) {
      this.createComponent(component, containerData.containerName)
    }
  }

  public createComponent(component: DynamicComponent, containerName: string): ComponentRef<DynamicComponent> {
    const componentRef: ComponentRef<DynamicComponent> = this.componentRefs.get(component.id) || this.viewContainerRef.createComponent<DynamicComponent>(component.componentType);
    this.renderer.addClass(componentRef.location.nativeElement, "dynamic-container");
    this.renderer.addClass(componentRef.location.nativeElement, "dynamic-container--" + containerName);
    this.renderer.addClass(componentRef.location.nativeElement, "dynamic-component");
    this.renderer.addClass(componentRef.location.nativeElement, "dynamic-component--" + component.id);
    this.componentRefs.set(component.id, componentRef);
    Object.assign(componentRef.instance, component.data);
    return componentRef;
  }

  public destroyComponent(id: string): void {
    const componentRef: ComponentRef<DynamicComponent> = this.componentRefs.get(id);
    if (componentRef) {
      componentRef.destroy();
      this.componentRefs.delete(id);
    }
  }
}
